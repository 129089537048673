import { FC, useEffect, useState } from "react";
import SelectFilter from "../../../../../UI/inputs/dropdown/SelectFilter";
import ScrollArea from "../../../../../UI/wrappers/ScrollArea";
import PlanshetIcon from "../../../../../../assets/icons/PlanshetIcon";
import { IScenario } from "../../../../../../assets/interfaces/IScenario";
import WorkWithScenary from "./WorkWithScenary";
import CrossIcon from "../../../../../../assets/icons/CrossIcon";
import Button from "../../../../../UI/buttons/Button";
import { IReference } from "../../../../../../assets/interfaces/IReference";
import { IPartner } from "../../../../../../assets/interfaces/IPartner";
import { selectedParntersFilter } from "../../../../../../API/base/exchangeApi/exchangeInterfaces";
import Scenario from "./Scenario";
import { IOrder } from "../../../../../../assets/interfaces/IOrder";
import Loader from "../../../../../UI/loader/loader";

interface IProps {
    scenarios: IScenario[] | undefined,
    basketReferences: IReference[],
    partners: IPartner[],
    filter: selectedParntersFilter,
    setFilter: (filter: selectedParntersFilter) => void,
    scenariosLoaded: boolean,
    repeatableScenario: IScenario | undefined,
    setMakedOrder: (newOrderData: IOrder) => void,
    isLoading?: boolean
}


const ScenariosList: FC<IProps> = ({ scenarios, partners, filter, setFilter, scenariosLoaded, basketReferences, repeatableScenario, setMakedOrder, isLoading }) => {
    interface PartnerInFilter extends IPartner {
        value: boolean,
    }

    const [requiredPartners, setrequiredPartners] = useState<PartnerInFilter[]>([]);
    const [excludePartners, setExcludePartners] = useState<PartnerInFilter[]>([]);
    const [scenarioForWork, setScenarioForWork] = useState<IScenario>();

    useEffect(() => {
        if (repeatableScenario) {
            setScenarioForWork(repeatableScenario)
        }
    }, [repeatableScenario])

    useEffect(() => {
        const filtPartners = convertPartnersToFilter(partners)
        setExcludePartners(filtPartners);
        setrequiredPartners(filtPartners);
    }, [partners])

    useEffect(() => {
        const exclude = partners.filter((p: IPartner) => {
            const inReq = filter.requiredPartners.find((rp) => rp === p.name)
            return inReq !== p.name
        })

        const required = partners.filter((p: IPartner) => {
            const inExclude = filter.excludePartners.find((ep) => ep === p.name)
            return inExclude !== p.name
        })

        setExcludePartners(convertPartnersToFilter(exclude))
        setrequiredPartners(convertPartnersToFilter(required))
    }, [filter])

    useEffect(() => {
        setScenarioForWork(undefined)
    }, [scenarios])

    useEffect(() => {
        if (repeatableScenario && basketReferences.length === 0) {
            setScenarioForWork(undefined)
        }
    }, [basketReferences, repeatableScenario])

    const convertPartnersToFilter = (partnersList: IPartner[]) => {
        return partnersList.map((p: IPartner) => {
            return { ...p, value: false } as PartnerInFilter
        })
    }

    const onSetFilter = (i: string, filtField: "required" | "exclude") => {
        let filters = { ...filter }
        switch (filtField) {
            case "exclude": {
                if (filters.excludePartners.includes(i)) {
                    filters = { ...filters, excludePartners: filters.excludePartners.filter((checkI) => checkI !== i) }
                }
                else {
                    filters.excludePartners.push(i)
                } break

            }
            case "required": {
                if (filters.requiredPartners.includes(i)) {
                    filters = { ...filters, requiredPartners: filters.requiredPartners.filter((checkI) => checkI !== i) }
                }
                else {
                    filters.requiredPartners.push(i)
                } break
            }
        }
        setFilter({ ...filters })
    }
    const scenariosList = scenarios?.map((s: IScenario) => <Scenario basketReferences={basketReferences} goToScenary={setScenarioForWork} data={s} key={s.scenarioId} />)

    return (<div className="flex flex-col w-full h-full">
        {typeof (scenarioForWork) !== "object" && <div className="flex flex-col w-full h-full gap-3 pt-5">
            <div className="flex w-full items-center gap-2 px-9 h-9">
                <h1 className="flex-1 text-lg font-semibold">Сценарии</h1>
                <div>
                    <SelectFilter
                        scrollClassName="max-h-[200px] pb-4"
                        containerClassName="w-[300px]"
                        clearFunk={() => setFilter({ ...filter, excludePartners: [] })}
                        title="Исключить партнеров"
                        searchable
                        onCheck={(item) => { onSetFilter(item.name, "exclude") }}
                        pickedValues={filter.excludePartners || []}
                        items={excludePartners}
                        getItemImg={(item) => item.avatar}
                        getItemTitle={(item) => item.name}
                        getItemValue={(item) => item.value} />
                </div>
                <div>
                    <SelectFilter
                        scrollClassName="max-h-[200px] pb-4"
                        containerClassName="w-[300px]"
                        clearFunk={() => setFilter({ ...filter, requiredPartners: [] })}
                        title="Обязательные партнеры"
                        searchable
                        onCheck={(item) => { onSetFilter(item.name, "required") }}
                        pickedValues={filter.requiredPartners || []}
                        items={requiredPartners}
                        getItemTitle={(item) => item.name}
                        getItemImg={(item) => item.avatar}
                        getItemValue={(item) => item.value} />

                </div>
            </div>
            {!isLoading ? <div className="flex-1 w-full overflow-hidden flex items-center justify-center">
                {scenariosList && scenariosList.length > 0 ? <ScrollArea className="px-9 pt-4">
                    <div className="w-full h-full flex flex-col gap-4">
                        {scenariosList}
                    </div>
                </ScrollArea> : !scenariosLoaded ?
                    <div className="flex flex-col gap-2 items-center">
                        <PlanshetIcon size={78} className="text-gray-2" />
                        <p className="text-base text-gray-3 text-center select-none">Здесь будет отображен список <br /> доступных сценариев</p>
                    </div> :
                    <div className="flex flex-col gap-2 items-center">
                        <CrossIcon size={78} className="text-gray-2" />
                        <p className="text-base text-gray-3 text-center select-none">Нет доступных сценариев</p>
                        {(filter.excludePartners.length > 0 || filter.requiredPartners.length > 0) &&
                            <Button
                                onClick={() => setFilter({ requiredPartners: [], excludePartners: [] })}
                                noFish
                                size="small"
                                variant="text"
                                className="w-auto">Сбросить фильтры</Button>}
                    </div>}
            </div> : <Loader />}
        </div>}
        {typeof (scenarioForWork) === "object" && <WorkWithScenary setMakedOrder={setMakedOrder} basketReferences={basketReferences} scenarioData={scenarioForWork} backFunk={() => setScenarioForWork(undefined)} />}
    </div>);
}

export default ScenariosList;