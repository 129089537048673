import { FC } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../UI/buttons/Button";
import clsx from "clsx";


interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    funk: () => void;
    headText?: string;
    text?: string;
    confirmBtnText?: string;
    className?: string;
    isDelete?: boolean;
}

const ConfirmModal: FC<IProps> = ({ isOpen, toggleOpen, headText, text, confirmBtnText, className, funk, isDelete }) => {
    return (
        <ModalBase className={clsx("rounded-lg", className)} onOpenChange={toggleOpen} open={isOpen}>
            <div className="w-[370px] flex flex-col items-center">
                <span className="w-full flex items-start justify-end -mt-6 -mr-16">
                    <IconButton onClick={toggleOpen} size={"small"}><CrossIcon size={16} /></IconButton>
                </span>
                <div className="w-full flex flex-col items-center gap-3 -mt-2">
                    <h1 className="w-full font-semibold text-lg">{headText}</h1>
                    <p className="mb-3 text-sm text-gray-3">{text}</p>
                    <div className="flex gap-4 w-full">
                        <Button onClick={funk} className="rounded-lg w-auto" noFish size="small" variant={!isDelete ? "primary" : "warning"}>{confirmBtnText}</Button>
                        <Button onClick={toggleOpen} className="rounded-lg w-auto" noFish size="small" variant="secondary">Отмена</Button>
                    </div>
                </div>
            </div>
        </ModalBase>
    );
}

export default ConfirmModal;