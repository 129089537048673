import { FC, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../UI/buttons/Button";
import clsx from "clsx";
import LinkButton from "../UI/buttons/LinkButton";
import { toast } from "react-toastify";
import CopyIcon from "../../assets/icons/CopyIcon";
import TextArea from "../UI/inputs/textInput/TextArea";
import { onTextCopy } from "../pages/basicPages/utils/utils";


interface IProps {
  isOpen: boolean;
  toggleOpen: () => void;
  className?: string;
  token: string | undefined;
  isLoading: boolean
}

const PartnerTokenModal: FC<IProps> = ({ isOpen, toggleOpen, className, token, isLoading }) => {
  const [tokenIsOpen, setTokenIsOpen] = useState<boolean>(false)
  return (
    <ModalBase className={clsx("rounded-lg", className)} onOpenChange={toggleOpen} open={isOpen}>
      <div className="w-[370px] flex flex-col items-center">
        <span className="w-full flex items-start justify-end -mt-6 -mr-16">
          <IconButton onClick={toggleOpen} size={"small"}><CrossIcon size={16} /></IconButton>
        </span>
        <div className="w-full flex flex-col items-center gap-6">
          <div className="flex flex-col gap-3">
            <h1 className="w-full font-semibold text-center text-lg">Уникальный токен авторизации партнёра</h1>
            <p className="text-sm text-center text-gray-3">Данный токен нельзя будет получить повторно, сохраните себе, что бы не потерять</p>
          </div>
          {tokenIsOpen ?
            <div className="w-full">
              <TextArea 
              className="min-h-[162px]" 
              label="Токен авторизации" 
              onClick={()=>token ? onTextCopy(token, "Токен") : toast.error("ошибка копирования")} 
              value={token} 
              />
            </div>
            :
            <LinkButton className="!text-black hover:!text-accent !text-lg" variant="primary" noFish onClick={()=>setTokenIsOpen(true)}>
              Скопировать токен <CopyIcon size={18} />
            </LinkButton>
          }
          <div className="flex w-full justify-center">
            <Button onClick={toggleOpen} className="rounded-lg w-auto" noFish size="small" variant="warning">Закрыть окно</Button>
          </div>
        </div>
      </div>
    </ModalBase >
  );
}

export default PartnerTokenModal;