import {useState} from "react";

import logo from "../../../../assets/logoBig.svg";
import RegForm from "../../../Forms/RegForm";
import AuthForm from "../../../Forms/AuthForm";

interface IProps {
	onAuth(): void;
}

const AuthRegPage = (props: IProps) => {
	const [isRegForm, setIsRegForm] = useState<boolean>(false);
	return (
		<div className="flex overflow-y-auto h-full w-full flex-col items-center  pt-8 bg-gray-1 pb-7">
			<div className="flex w-[500px] flex-col mb-5 items-center justify-start flex-1">
				<div className="flex mb-12 w-full justify-center">
					<div className="pr-12 border-r-gray-2 border-r"><img src={logo} alt="" /></div>
					<div className="pl-12">
						<p className="text-left text-gray-4">Биржа складских <br /> остатков</p>
					</div>
				</div>
				<div className="mb-[30px] flex flex-col w-full gap-4">
					<div className="flex font-semibold select-none">
						<p
							onClick={() => setIsRegForm(false)}
							className={`flex-1 w-[50%] text-gray-4 ${!isRegForm && "!text-accent"} text-sm text-center cursor-pointer`}
						>
							Вход
						</p>
						<p
							onClick={() => setIsRegForm(true)}
							className={`flex-1 w-[50%] text-gray-4 ${isRegForm && "!text-accent"} text-sm text-center cursor-pointer`}
						>
							Регистрация
						</p>
					</div>
					<div className="relative rounded-sm h-[2px] bg-gray-2">
						<span
							className={`${isRegForm ? "left-1/2" : "left-0"
								} absolute bottom-0 h-[2px] w-[50%] rounded-sm bg-accent duration-200`}
						></span>
					</div>
				</div>
				{!isRegForm ? <AuthForm onAuth={props.onAuth} /> : <RegForm onAuth={props.onAuth}/>}
			</div>
			<div className="w-full flex flex-col items-center gap-4">
				<p className="text-gray-4">Центр поддержки клиентов Systeme Electric:</p>
				<div className="flex gap-4 font-semibold">
					<a href="tel:8 800 200 64 46">8 800 200 64 46</a>
					<a href="tel:+7 495 777 99 88">+7 495 777 99 88</a>
					<a href="mailto:support@systeme.ru">support@systeme.ru</a>
				</div>
			</div>
		</div>
	);
};

export default AuthRegPage;
