import {useState } from "react";
import {useNavigate } from "react-router-dom";
import logo from "../../assets/logoBig.svg"
import AuthorizedRoutes from "../../routes/routes";
import DropdownMenu from "../UI/inputs/dropdown/DropdownMenu";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import UserIcon from "../../assets/icons/UserIcon";
import LogOutIcon from "../../assets/icons/LogOutIcon";
import { getProfileResponse } from "../../API/base/profileApi/profileInterfaces";
import HistoryIcon from "../../assets/icons/HistoryIcon";

interface IProps {
	onLogout: () => void;
	profileInfo: getProfileResponse | undefined; //интерфейс профиля пока не определен
}

const Header = (props: IProps) => {
	const [menu, setMenu] = useState<boolean>(false);
	const toggleMenu = () => {
		setMenu(!menu)
	}
	const navigate = useNavigate();
	const toOrderHistory = <div onClick={() => {
		navigate(AuthorizedRoutes.OrderHistory, { state: { prevPath: "check" } })
	}} className="flex items-center gap-2 w-44">
		<HistoryIcon size={18} className="text-accent" />
		<p className="font-medium">История заказов</p>
	</div>
	const toProfileOption = <div onClick={() => {
		navigate(AuthorizedRoutes.Profile, { state: { prevPath: "check" } })
	}} className="flex items-center gap-2 w-44">
		<UserIcon size={18} className="text-accent" />
		<p className="font-medium">Профиль</p>
	</div>

	const exitOption = <div onClick={() => props.onLogout()} className="flex items-center gap-2 w-full">
		<LogOutIcon size={18} className="text-system-red" />
		<p className="font-medium">Выйти</p>
	</div>

	return (
		<header className="flex w-full h-[52px] min-h-[52px] min-w-[770px] justify-end items-center bg-gray-1 px-9">
			<div className="flex flex-1 justify-start items-center h-8">
				<img onClick={() => navigate("/")} src={logo} alt="logo" className="h-full pr-5 cursor-pointer" />
				<div className="text-gray-3 border-l border-gray-2 pl-5 min-w-[260px] h-8 text-sm leading-4">
					Биржа складских <br /> остатков
				</div>
			</div>
			<div className="flex flex-1 justify-end items-center gap-5">
				<DropdownMenu onOpenChange={() => toggleMenu()} items={[toOrderHistory, toProfileOption, exitOption]}>
					<div className="flex items-center gap-3">
						<UserIcon size={18} className="text-gray-3" />
						<div className="flex flex-col text-sm leading-4">
							<p className="font-medium">{`${props.profileInfo?.firstName} ${props.profileInfo?.lastName}`}</p>
							<p className="text-gray-4">{props.profileInfo?.companyName}</p>
						</div>
						<ArrowRightIcon className={`text-${!menu ? "gray-3" : "accent"} hover:text-accent ${!menu ? "rotate-90" : "-rotate-90"}  transition-all`} size={20} />
					</div>
				</DropdownMenu>
			</div>
		</header>
	);
};

export default Header;
