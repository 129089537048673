import { useEffect, useState } from "react";
import AuthRegPage from "./components/pages/basicPages/AuthRegPage/AuthRegPage";
import clsx from "clsx";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { ToastContainer } from "react-toastify";
import InfoIcon from "./assets/icons/InfoIcon";
import CheckMarkIcon from "./assets/icons/CheckMarkIcon";
import ExchangePage from "./components/pages/basicPages/ExchangePage/ExchangePage";
import Header from "./components/layout/Header";
import { getProfileResponse } from "./API/base/profileApi/profileInterfaces";
import ProfilePage from "./components/pages/basicPages/ProfilePage/ProfilePage";
import AdminPage from "./components/pages/adminPages/AdminPage";
import OrderHistoryPage from "./components/pages/basicPages/OrderHistoryPage/OrderHistoryPage";
import { GetProfileInfo } from "./API/base/profileApi/profileFunctions";
import AuthAdminPage from "./components/pages/adminPages/AdminAuthPage";
import AppRoutes from "./routes/routes";
import { AdminLogout, GetAdminProfile } from "./API/admin/adminAuthApi/AdminAuthFunctions";
import { GetAdminProfileResponse } from "./API/admin/adminAuthApi/AdminAuthInterfaces";
import { LogOut } from "./API/base/authRegApi/authRegFunctions";
import 'react-toastify/dist/ReactToastify.css';

interface ILayout {
  noHeader?: boolean
}

const InnerApp = () => {
  const lastLoginIsAdmin = localStorage.getItem("lastIsAdmin") === "true";
  const navigate = useNavigate();
  const [isAuth, setAuth] = useState<boolean>();
  const [isAdminAuth, setIsAdminAuth] = useState<boolean>();
  const [profileInfo, setProfileInfo] = useState<getProfileResponse>();
  const [adminProfileInfo, setAdminProfileInfo] = useState<GetAdminProfileResponse>();
  const [authCheched, setAuthChecked] = useState<boolean>(false)

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const specUuid = searchParams.get('id');
    if (specUuid) {
      localStorage.setItem("linkSpecId", specUuid)
    }
    if (!lastLoginIsAdmin) {
      GetProfileInfo().then((r) => {
        if (r.status !== "error") {
          setProfileInfo(r.data);
          onAuth();
        } else {
          setAuth(false);
        }
        setAuthChecked(true)
      });
    }
    else {
      GetAdminProfile().then((r) => {
        if (r.status !== "error") {
          setAdminProfileInfo(r.data);
          onAdminAuth();
        } else {
          setIsAdminAuth(false);
        }
        setAuthChecked(true)
      });
    }
  }, []);

  const onAuth = (isFirstAuth: boolean = false) => {
    if (isFirstAuth) {
      GetProfileInfo().then((r) => {
        if (r.status !== "error") {
          setProfileInfo(r.data);
        }
      });
    }
    localStorage.setItem("lastIsAdmin", "false");
    setAuth(true);
    setIsAdminAuth(false);
  };


  const onAdminAuth = (isFirstAuth: boolean = false) => {
    if (isFirstAuth) {
      GetAdminProfile().then((r) => {
        if (r.status !== "error") {
          setAdminProfileInfo(r.data);
        }
      });
    }
    localStorage.setItem("lastIsAdmin", "true");
    setIsAdminAuth(true);
    setAuth(false);
  };


  const onLogout = (isAdmin: boolean) => {
    if (!isAdmin) {
      LogOut().then((res) => {
        if (res.status === "error") {
          return;
        }
        setAuth(false);
        navigate("/", { replace: true });
      });
    } else {
      AdminLogout().then((res) => {
        if (res.status === "error") {
          return;
        }
        setIsAdminAuth(false);
        navigate(AppRoutes.AdminAuth, { replace: true });
      });
    }
  };

  const onRefreshProfile = () => {
    GetProfileInfo().then((r) => {
      if (r.status !== "error") {
        setProfileInfo(r.data);
      }
    });
  };

  const Layout = ({ noHeader }: ILayout) => (
    <div className="h-screen flex flex-col">
      <div className="w-full min-w-[1354px] h-full flex-1 overflow-y-auto">
        {!noHeader && <Header onLogout={() => onLogout(false)} profileInfo={profileInfo} />}
        <Outlet />
      </div>
    </div>
  );
  return (
    <Routes>
      <>
        {!isAuth && !isAdminAuth ? (
          <>
            {authCheched && <Route path="*" element={<Navigate to={!lastLoginIsAdmin ? "/" : AppRoutes.AdminAuth} replace />} />}
            {!lastLoginIsAdmin && <Route path={AppRoutes.Admin} element={<Navigate to={AppRoutes.AdminAuth} replace />} />}
            <Route path="/" element={<AuthRegPage onAuth={() => onAuth(true)} />} />
            <Route path={AppRoutes.AdminAuth} element={<AuthAdminPage onAuth={() => onAdminAuth(true)} />} />
          </>
        ) : (
          <>
            {!isAdminAuth ? (
              <Route
                path="/"
                element={
                  <div className="overflow-y-hidden overflow-x-hidden">
                    <Layout />
                  </div>
                }
              >
                {/* Authorized routes place for base app */}
                <Route path={AppRoutes.Exchange} element={<ExchangePage/>} />
                <Route path={AppRoutes.Profile} element={<ProfilePage onRefreshProfile={onRefreshProfile} profileInfo={profileInfo} onLogout={() => onLogout(false)} />} />
                <Route path={AppRoutes.OrderHistory} element={<OrderHistoryPage />} />
                <Route path="/" element={<Navigate to={AppRoutes.Exchange} />} />
                <Route path="*" element={<Navigate to={AppRoutes.Exchange} />} />
              </Route>
            ) : (
              <Route
                path="/"
                element={
                  <div className="overflow-y-hidden overflow-x-hidden">
                    <Layout noHeader />
                  </div>
                }
              >
                {/* Authorized routes place for admin app part */}
                <Route path={AppRoutes.Admin} element={<AdminPage adminProfileInfo={adminProfileInfo as GetAdminProfileResponse} onLogout={() => onLogout(true)} />} />
                <Route path="*" element={<Navigate to={AppRoutes.Admin} />} />
                <Route path="/" element={<Navigate to={AppRoutes.Admin} />} />
              </Route>
            )}
          </>
        )}</>
    </Routes>
  );
};

function App() {
  return (
    <BrowserRouter>
      <TooltipProvider>
        <InnerApp />
      </TooltipProvider>
      <ToastContainer
        autoClose={3000}
        bodyClassName={(context) =>
          clsx(
            context?.defaultClassName,
            "flex items-center gap-3 text-black [&>div]:first:me-0 [&>div]:first:w-auto",
          )
        }
        className={(context) => clsx(context?.defaultClassName, "w-64")}
        closeButton={false}
        draggable={false}
        hideProgressBar={true}
        icon={({ type }) => {
          switch (type) {
            case "success":
              return <CheckMarkIcon className="text-accent" size={24} />;
            case "error":
              return <InfoIcon className="text-system-red" size={24} />;
            default:
              return null;
          }
        }}
        position="bottom-left"
        progressClassName={(context) => clsx(context?.defaultClassName, "h-0")}
        toastClassName={(context) =>
          clsx(
            context?.defaultClassName,
            "min-h-15 min-h-0 rounded-xl bg-white p-4 shadow-1",
          )
        }
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;
