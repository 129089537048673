import { FC } from "react";
import { IPartner } from "../../../../../../assets/interfaces/IPartner";
import systemeIcon from "../../../../../../assets/systemeIcon.svg"

interface IProps {
    data: IPartner;
    onClick: () => void;
}

const PartnerAdminListItem: FC<IProps> = ({ data, onClick }) => {
    return (<div onClick={onClick} className="w-[186px] h-56 bg-gray-1 rounded-lg flex flex-col p-4 items-center gap-8 cursor-pointer hover:bg-gray-2 transition-all">
        <img className="w-20 h-20 select-none" src={data.avatar || systemeIcon} alt="logo" />
        <p className="w-full text-left text-base">{data.name}</p>
    </div>);
}

export default PartnerAdminListItem;