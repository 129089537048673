import { FC, useEffect, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../UI/buttons/Button";
import clsx from "clsx";
import { IPartner } from "../../assets/interfaces/IPartner";
import { Basket, IScenario } from "../../assets/interfaces/IScenario";
import { GetDistributionInfo } from "../../API/base/exchangeApi/exchangeFunctions";
import { ResponseContainer } from "../../API/BaseApi";
import { getDistributionInfoResponse } from "../../API/base/exchangeApi/exchangeInterfaces";
import ScrollArea from "../UI/wrappers/ScrollArea";
import Counter from "../UI/inputs/textInput/Counter";
import PlusIcon from "../../assets/icons/PlusIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import { IReference } from "../../assets/interfaces/IReference";
import Loader from "../UI/loader/loader";
import systemeIcon from "../../assets/systemeIcon.svg"


interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    className?: string;
    refSku: string;
    inBasketCount: number;
    unllocatedCount: number;
    scenarioPartners: IPartner[];
    scenarioData: IScenario;
    saveFunk: (scenario: IScenario) => void;
    basketReferences: IReference[],

}

interface DistributePartner extends IPartner {
    inStockCount: number,
    count?: number,
}

const AllocationModal: FC<IProps> = ({ isOpen, toggleOpen, refSku, className, scenarioPartners, scenarioData, inBasketCount, unllocatedCount, basketReferences, saveFunk }) => {
    const [distibutePartners, setDistributePartners] = useState<DistributePartner[]>([])
    const [unllocatedCounter, setUnllocatedCounter] = useState<number>(unllocatedCount)
    const [distributionInfoLoading, setDistributionInfoLoading] = useState<boolean>(false)

    useEffect(() => {
        if (isOpen) {
            onGetDistributePartnersInfo()
        }
    }, [isOpen, scenarioData])

    useEffect(() => {
        if (distibutePartners.length > 0) onSetUnllocatedValue()
    }, [distibutePartners])

    const onSetUnllocatedValue = () => {
        let distrCount = 0;
        for (const dPartner of distibutePartners) {
            if (dPartner.count && dPartner.count > 0) {
                distrCount += dPartner.count
            }
        }
        const unllocatedCount = inBasketCount - distrCount
        setUnllocatedCounter(unllocatedCount)
    }
    const onGetDistributePartnersInfo = () => {
        const partnersSku = scenarioPartners.map((p) => p.id)
        setDistributionInfoLoading(true)
        GetDistributionInfo({ partners: partnersSku, referenceSku: refSku }).then((r: ResponseContainer<getDistributionInfoResponse>) => {
            if (r.status !== "error") {
                const partners: DistributePartner[] = []
                for (const scenarioPartner of scenarioPartners) {
                    const partnerDistributeInfo = r.data?.partners.find((p) => p.partnerId === scenarioPartner.id)
                    let partnerRefCount
                    for (const basket of scenarioData.baskets) {
                        if (basket.partnerId === scenarioPartner.id) {
                            partnerRefCount = basket.references.find((r) => r.referenceSku === refSku)?.count
                        }
                    }
                    const newDistributePartner: DistributePartner = { ...scenarioPartner, inStockCount: partnerDistributeInfo?.inStockCount as number, count: partnerRefCount }
                    partners.push(newDistributePartner)
                }
                setDistributePartners(partners)
            }
            setDistributionInfoLoading(false)
        })
    }

    const onChangePartnerRefCount = (newCount: number | undefined = undefined, partnerId: string) => {
        const newDistributePartners = [...distibutePartners]
        const partnerIndex = distibutePartners.findIndex((p) => p.id === partnerId)
        const partnerDistributeInfo: DistributePartner = { ...newDistributePartners[partnerIndex] }
        if (typeof (newCount) === "number") {
            if (newCount - (partnerDistributeInfo.count || 0) <= unllocatedCounter) {
                partnerDistributeInfo.count = newCount
            }
            else {
                //@ts-ignore
                partnerDistributeInfo.count = partnerDistributeInfo.count + unllocatedCounter
            }
        }
        else {
            partnerDistributeInfo.count = newCount
        }
        newDistributePartners[partnerIndex] = partnerDistributeInfo
        setDistributePartners(newDistributePartners)
    }

    const onAddPartnerToDistribute = (partnerId: string) => {
        onChangePartnerRefCount(1, partnerId)
    }

    const onDeletePartnerFromDistribute = (partnerId: string) => {
        onChangePartnerRefCount(undefined, partnerId)
    }

    const distibutePartnersList = distibutePartners.map((p) => {
        return <div key={p.id} className="w-full flex gap-3 items-center">
            <img className="select-none w-8 h-8 rounded-[7px]" src={p.avatar || systemeIcon} alt="logo" />
            <div className="flex flex-col flex-1">
                <div className="flex items-center">
                    <p className="font-semibold">{p.name}</p>

                </div>
                <p className="text-xs text-gray-3">В наличии: {p.inStockCount || 0}</p>
            </div>
            <div>
                {typeof (p.count) !== "number" ?
                    <Button disabled={unllocatedCounter === 0 || !p.inStockCount} onClick={() => onAddPartnerToDistribute(p.id)} size="small" noFish className="w-auto rounded-lg"><PlusIcon size={18} /> Добавить</Button> :
                    <div className="flex gap-2 items-center">
                        <IconButton onClick={() => onDeletePartnerFromDistribute(p.id)} className="!bg-gray-1 !h-8 !w-8"><TrashIcon size={18} /></IconButton>
                        <Counter disableIncrement={unllocatedCounter === 0} setNumber={(count: number) => { onChangePartnerRefCount(count, p.id) }} size="big" value={p.count as number} maxValue={p.inStockCount} />
                    </div>}
            </div>
        </div>
    })

    const onSaveDistribution = () => {
        const newScenarioData: IScenario = { ...scenarioData }
        let newBaskets: Basket[] = [...newScenarioData.baskets]
        for (const p of distibutePartners) {
            const distributedRefData: IReference = { ...basketReferences.find((r) => r.referenceSku === refSku), count: p.count, inStockCount: p.inStockCount } as IReference
            const basketIndexInScenario = newScenarioData.baskets.findIndex((b) => b.partnerId === p.id)
            if (basketIndexInScenario >= 0) {
                const newBasketData = { ...newScenarioData.baskets[basketIndexInScenario] }
                const basketRefIndex = newBasketData.references.findIndex((r) => r.referenceSku === refSku)
                if (basketRefIndex >= 0) {
                    if (distributedRefData.count && distributedRefData.count > 0) {
                        newBasketData.references[basketRefIndex] = { ...distributedRefData }
                    }
                    else {
                        newBasketData.references = [...newBasketData.references.filter((r) => r.referenceSku !== refSku)]
                    }
                }
                else {
                    if (distributedRefData.count as number > 0) {
                        newBasketData.references = [...newBasketData.references, distributedRefData]
                    }
                    else {
                        newBasketData.references = newBasketData.references.filter((r) => r.count && r.count > 0)
                    }
                }
                if (newBasketData.references.length > 0) {
                    newBaskets[basketIndexInScenario] = { ...newBasketData }
                }
                else {
                    newBaskets = newBaskets.filter((b) => b.partnerId !== p.id)
                }
            }
            else {
                if (distributedRefData.count) {
                    newBaskets.push({ partnerImg: p.avatar, partnerName: p.name, partnerId: p.id, references: [distributedRefData] })
                }
            }
        }
        newScenarioData.baskets = [...newBaskets]
        saveFunk(newScenarioData)
        toggleOpen()
    }

    return (
        <ModalBase className={clsx("rounded-lg !p-0", className)} onOpenChange={toggleOpen} open={isOpen}>
            <div className="w-[520px] flex flex-col items-center h-[520px] pt-8 overflow-hidden">
                <span className="w-full flex items-start justify-end -mt-5 mr-5">
                    <IconButton onClick={toggleOpen} size={"small"}><CrossIcon size={16} /></IconButton>
                </span>
                <div className="w-full flex flex-col gap-4 -mt-2 px-8">
                    <h1 className="w-full font-semibold text-lg">Распределение</h1>
                    <div className="flex items-center">
                        <p className="flex-1 text-gray-3 text-sm">Референс: <span className="text-accent">{refSku}</span></p>
                        <p className="text-gray-3 text-sm">Нераспределено: <span className="rounded-[4px] bg-gray-1 text-center text-black py-1 px-2 !w-[100px]">{unllocatedCounter}</span></p>
                    </div>
                </div>
                <div className="flex-1 w-full px-4 overflow-hidden pt-5">
                    {!distributionInfoLoading ? <ScrollArea className="px-4">
                        <div className="flex flex-col gap-5">
                            {distibutePartnersList}
                        </div>
                    </ScrollArea> : <Loader />}
                </div>
                <div className="flex gap-4 w-full h-[76px] shadow-5 items-center px-8">
                    <Button disabled={unllocatedCounter !== 0} size="small" onClick={onSaveDistribution} className="rounded-lg w-auto" noFish >Сохранить</Button>
                    <Button size="small" onClick={toggleOpen} className="rounded-lg w-auto" noFish variant="secondary">Отмена</Button>
                </div>
            </div>
        </ModalBase>
    );
}

export default AllocationModal;