import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import React from "react";

import Paper from "../../layout/Paper";

export interface ModalProps {
	children: React.ReactNode;
	open?: boolean;
	onOpenChange: (open: boolean) => void;

	className?: string;
	styleClasses?: {
		overlay?: string;
	};
}

export interface ModalPropsNoChildren {
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}

const ModalBase = (props: ModalProps) => {
	return (
		<Dialog.Root open={props.open} onOpenChange={props.onOpenChange}>
			<Dialog.Portal>
				<Dialog.Overlay
					className={clsx(
						"fixed inset-0 z-40 bg-blackout opacity-0 transition-opacity data-[state=open]:opacity-100",
						props.styleClasses?.overlay,
					)}
				/>
				<Dialog.Content className="fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] outline-none">
					<Paper
						className={clsx(
							"flex max-h-[calc(100vh-5rem)] flex-col gap-6 overflow-hidden",
							props.className,
						)}
					>
						{props.children}
					</Paper>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export default ModalBase;
