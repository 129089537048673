import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "../../../../UI/buttons/Button";
import clsx from "clsx";
import CustomRadioGroup from "../../../../UI/buttons/CustomRadioGroup";
import { DateRange, } from "react-day-picker";
import { EExportType, EReportDataType, EReportDate, EReportPartnersExportType, EReportSplitDate, EReportType, ESplitType, IReport, ReportDates } from "./interfaces/IAdminAnalytics";
import TextBoxDefault from "../../../../UI/inputs/textInput/TextBoxDefault";
import { format } from "date-fns";
import DateIcon from "../../../../../assets/icons/DateIcon";
import ChipsBox from "../../../../UI/inputs/ChipsBox";
import Select from "../../../../UI/inputs/dropdown/Select";
import Switch from "../../../../UI/inputs/Switch";
import { GetPayload } from "../../../../../API/base/payloadApi/payloadFunctions";
import { ResponseContainer } from "../../../../../API/BaseApi";
import { GetPayloadResponse } from "../../../../../API/base/payloadApi/payloadInterfaces";
import { IPartner } from "../../../../../assets/interfaces/IPartner";
import Calendar from "../../../../UI/calendar/Calendar";
import { IReportRequest } from "../../../../../API/admin/analyticsApi/analyticsInterfaces";
import { DownloadAnalytics } from "../../../../../API/admin/analyticsApi/analyticsFunctions";
import { toast } from "react-toastify";


interface IProps {

}

const Analytics: FC<IProps> = () => {
  const [range, setRange] = useState<DateRange>();
  const startRef = useRef<HTMLDivElement>(null)
  const [type, setType] = useState<'start' | 'end' | 'none'>('none');

  const [isSplit, setIsSplit] = useState(true)

  //TODO Как будет эндпоинт заменить заглушку
  const selectTextItems = [
    {
      label: "линейка 1",
      value: "линейка 1"
    },
    {
      label: "линейка 2",
      value: "линейка 2"
    },
    {
      label: "линейка 3",
      value: "линейка 3"
    },
  ]

  const [report, setReport] = useState<IReport>({
    type: EReportType.Partners,
    period: EReportDate.Week,
    format: EExportType.CSV,
    reportDataType: EReportDataType.All,
    selectType: EReportPartnersExportType.All,
    date: undefined,
    selected: undefined,
    split: EReportSplitDate.Day,
    splitGroup: ESplitType.Partners,

    // Параметры отчета
    transactionsValue: false,
    transactionsSummary: false,

    // Партнеры
    transitValue: false,
    totalPartnerCartCost: false,
    averageUserCartCost: false,
    nomenclaturePartEquipInUserCart: false,
    moneyPartEquipInUserCart: false,

    // Референсы
    line: undefined,
    addictionNumber: undefined,
    averageCountInUserCart: undefined,
  });

  const getPartnerById = (id: string) => {
    return partners.find((item) => item.id === id)
  }

  const [partners, setPartners] = useState<IPartner[]>([])

  const onGetPayload = () => {
    GetPayload({ partners: true }).then((r: ResponseContainer<GetPayloadResponse>) => {
      setPartners(r.data?.partners || [])
    })
  }

  useEffect(() => {
    onGetPayload()
  }, [])

  useEffect(() => {
    if (report.type === EReportType.Partners) {
      const defaultReport: IReport = {
        // Общее
        type: EReportType.Partners,
        period: EReportDate.Week,
        format: EExportType.CSV,
        reportDataType: EReportDataType.All,
        selectType: EReportPartnersExportType.All,
        date: undefined,
        selected: undefined,
        split: EReportSplitDate.Day,
        splitGroup: ESplitType.Partners,

        // Параметры отчета
        transactionsValue: false,
        transactionsSummary: false,

        // Партнеры
        transitValue: false,
        totalPartnerCartCost: false,
        averageUserCartCost: false,
        nomenclaturePartEquipInUserCart: false,
        moneyPartEquipInUserCart: false,

        // Референсы
        line: undefined,
        addictionNumber: false,
        averageCountInUserCart: false,
      }
      setReport(defaultReport)
    }
    if (report.type === EReportType.References) {
      const defaultReport: IReport = {
        // Общее
        type: EReportType.References,
        period: EReportDate.Week,
        format: EExportType.CSV,
        reportDataType: EReportDataType.All,
        selectType: EReportPartnersExportType.Selected,
        date: undefined,
        selected: undefined,
        split: EReportSplitDate.Day,
        splitGroup: ESplitType.Lines,

        // Параметры отчета
        transactionsValue: false,
        transactionsSummary: false,

        // Партнеры
        transitValue: false,
        totalPartnerCartCost: false,
        averageUserCartCost: false,
        nomenclaturePartEquipInUserCart: false,
        moneyPartEquipInUserCart: false,

        // Референсы
        line: undefined,
        addictionNumber: false,
        averageCountInUserCart: false,
      }
      setReport(defaultReport)
      setIsSplit(true)
    }
  }, [report.type])

  useEffect(() => {
    if (!isSplit) {
      setReport({ ...report, split: undefined, splitGroup: undefined });
    }
    if (isSplit && report.type === EReportType.Partners) {
      setReport({ ...report, split: EReportSplitDate.Day, splitGroup: ESplitType.Partners });
    }
    if (isSplit && report.type === EReportType.References) {
      setReport({ ...report, split: EReportSplitDate.Day, splitGroup: ESplitType.Lines });
    }
  }, [isSplit])


  const formatReport = (report: IReport): IReportRequest => {
    const newReport: IReportRequest = { ...report } as IReportRequest;
    if (report.date?.start && report.date.end) {
      newReport.date = {
        start: String(format(report.date.start, 'dd.MM.yy')),
        end: String(format(report.date.end, 'dd.MM.yy'))
      };
    }
    return newReport;
  };

  const onDownloadReport = () => {
    DownloadAnalytics(formatReport(report)).then((response) => {
      if (response.status = "error") {
        toast.success(response.message)
        return
      }
      else {
        toast.error(response.message)
      }
      window.open(response.data?.link)
    })
  }

  //TODO Как появится эндпоинт переписать функцию
  const onDownloadLogs = () => {
    console.log(report);
    // DownloadAnalytics(report).then((response) => {
    //   if (response.status = "error") {
    //     alert(response.message)
    //     return
    //   }
    //   window.open(response.data?.link)
    // })
  }

  const handleDateSelect = (selectDate: Date, type: 'start' | 'end') => {
    if (type === 'start') {
      setReport({ ...report, period: EReportDate.Custom, date: { ...report.date, start: selectDate } });
    } else if (type === 'end') {
      setReport({ ...report, period: EReportDate.Custom, date: { ...report.date, end: selectDate } });
    }
  };


  return (
    <div className="flex flex-col p-10 overflow-y-auto">
      <div className='flex gap-2 mb-10'>
        <Button noFish variant='text'
          size='small'
          className={clsx('w-fit shadow-1 shadow-gray-1 text-gray-3', report.type === EReportType.Partners && "!text-accent scale-105 shadow-2")}
          onClick={() => { setReport({ ...report, type: EReportType.Partners }) }}
        >
          По партнерам
        </Button>
        <Button noFish variant='text'
          size='small'
          className={clsx('w-fit shadow-1 shadow-gray-1 text-gray-3', report.type === EReportType.References && "!text-accent scale-105 shadow-2")}
          onClick={() => { setReport({ ...report, type: EReportType.References }) }}
        >
          По референсам
        </Button>
      </div>
      <div className='flex flex-col mb-10'>
        <div className='w-full flex gap-6'>
          <CustomRadioGroup
            label='Период'
            items={ReportDates}
            value={report.period}
            getItemTitle={(item) => item.label}
            getItemValue={(item) => item.value}
            onValueChange={(value) => {
              if (value !== EReportDate.Custom) {
                setReport({ ...report, period: value as EReportDate, date: undefined })
                setRange(undefined)
              } else {
                setReport({ ...report, period: value as EReportDate })
              }
            }}
            styleClasses={{
              root: '!min-h-[48px]',
              item: 'h-full'
            }}
          />
          <div className='flex gap-2'>
            <span ref={startRef} className='flex flex-col relative'  >
              <TextBoxDefault
                label='Дата начала'
                value={report.date?.start ? String(format(report.date?.start, 'dd.MM.yy')) : ''}
                className='max-w-[130px]'
                icon={<DateIcon className='text-gray-3 hover:text-accent' />}
                iconAnchor='right'
                onIconClick={() => type === 'start' ? setType('start') : setType('start')}
              />
              {type === 'start' && <div onMouseLeave={(e) => { setType('none') }} className={clsx('absolute bg-white shadow-2 top-[68px] flex items-center justify-center z-50')}>
                <Calendar selectDate={(date: Date) => { handleDateSelect(date, 'start') }} />
              </div>}
            </span>
            <span className='flex flex-col relative'  >
              <TextBoxDefault
                label='Дата окончания'
                className='max-w-[130px]'
                value={report.date?.end ? String(format(report.date?.end, 'dd.MM.yy')) : ''}
                icon={<DateIcon className='text-gray-3 hover:text-accent' />}
                iconAnchor='right'
                onIconClick={() => { setType('end') }}
              />
              {type === 'end' && <div onMouseLeave={(e) => { setType('none') }} className={clsx('absolute bg-white shadow-2 top-[68px] flex items-center justify-center z-50')}>
                <Calendar selectDate={(date: Date) => { handleDateSelect(date, 'end') }} />
              </div>}
            </span>
          </div>
        </div>
      </div>
      <div className='flex flex-col mb-10'>
        <div className="text-base font-medium mb-6">Параметры отчёта</div>
        <div className="flex gap-6">
          <CustomRadioGroup
            label='Формат отчета'
            value={report.format}
            items={[
              { value: EExportType.CSV, label: 'CSV' },
              { value: EExportType.XLSX, label: 'XLSX' }
            ]}
            getItemTitle={(item) => item.label}
            getItemValue={(item) => item.value}
            onValueChange={(value) => { setReport({ ...report, format: value as EExportType }) }}
          />
          <CustomRadioGroup
            label='Данные отчёта'
            value={report.reportDataType}
            items={[
              { value: EReportDataType.All, label: 'По всем' },
              { value: EReportDataType.Cart, label: 'По корзине' },
              { value: EReportDataType.Exchange, label: 'По бирже' },
            ]}
            getItemTitle={(item) => item.label}
            getItemValue={(item) => item.value}
            onValueChange={(value) => { setReport({ ...report, reportDataType: value as EReportDataType }) }}
          />
        </div>
      </div>
      {report.type === EReportType.Partners &&
        <div className="flex items-center mb-10 gap-6">
          <CustomRadioGroup
            label='Объекты экспорта'
            value={report.selectType}
            items={[
              { value: EReportPartnersExportType.All, label: 'Все' },
              { value: EReportPartnersExportType.Selected, label: 'Выбранные' },
            ]}
            getItemTitle={(item) => item.label}
            getItemValue={(item) => item.value}
            onValueChange={(value) => setReport({ ...report, selectType: value as EReportPartnersExportType })}
          />
          {report.selectType === EReportPartnersExportType.Selected && (report.type === EReportType.Partners ? (
            <ChipsBox
              label='Партнеры'
              placeholder='Начните вводить название партнера'
              items={report.selected || []}
              allItems={partners ? partners.map(it => it.id) : []}
              getItemTitle={(item) => getPartnerById(item)?.name || ''}
              getItemValue={(item) => getPartnerById(item)?.id || ''}
              getItemByValue={(item) => getPartnerById(item)?.id || ''}
              onDeleteItem={(item) => {
                if (!report.selected) return
                let ind = report.selected.indexOf(item)
                if (ind !== -1) {
                  setReport({ ...report, selected: [...report.selected.slice(0, ind), ...report.selected.slice(ind + 1)] })
                }
              }
              }
              onSelectItem={(item) => {
                if (!report.selected) report.selected = []
                setReport({ ...report, selected: [...report.selected, item] })
              }}
            />
          ) : (
            <div>Линейки потом</div>
          ))}
        </div>
      }
      {report.type === EReportType.References &&
        <div className="flex items-center mb-10 gap-6">
          <Select
            className="!w-[424px]"
            onValueChange={(item: string) => {
              setReport({ ...report, line: item });
            }}
            value={report.line}
            items={selectTextItems}
            getItemValue={(item) => item.value}
            getItemTitle={(item) => item.label}
            label="Линейка"
            placeholder="Выберите линейку"
          />
        </div>
      }
      <div className='flex flex-col mb-10'>
        <div className="mb-6">Формат таблицы</div>
        <Switch
          label='Разбивка по:'
          checked={isSplit}
          onCheckedChange={() => {
            setIsSplit(!isSplit)
          }}
        />
        <div className={`${!isSplit ? 'hidden' : 'block'}`}>
          <div className="flex gap-6 items-center cursor-default">
            <CustomRadioGroup
              value={report.split}
              items={[
                { value: EReportSplitDate.Day, label: "День" },
                { value: EReportSplitDate.Week, label: "Неделя" },
                { value: EReportSplitDate.Month, label: "Месяц" },
                { value: EReportSplitDate.Year, label: "Год" },
              ]}
              getItemTitle={(item) => item.label}
              getItemValue={(item) => item.value}
              onValueChange={(value) => { setReport({ ...report, split: value as EReportSplitDate }) }}
            />
            <CustomRadioGroup
              label='Группировка отчёта'
              value={report.splitGroup}
              items={report.type === EReportType.Partners ? [
                { value: ESplitType.Partners, label: 'По партнерам' },
                { value: ESplitType.Time, label: 'По промежутку времени' }
              ] : [
                { value: ESplitType.Lines, label: 'По линейкам' },
                { value: ESplitType.Time, label: 'По промежутку времени' }
              ]}
              getItemTitle={(item) => item.label}
              getItemValue={(item) => item.value}
              onValueChange={(value) => {
                if (value === ESplitType.Lines) {
                  setReport({ ...report, selectType: EReportPartnersExportType.Selected, splitGroup: value as ESplitType })
                  return
                }
                setReport({ ...report, splitGroup: value as ESplitType })
              }}
            />
          </div>
        </div>

      </div>
      <div>
        <div className="flex flex-col gap-2 mb-2">
          <Switch
            label='Количество транзакций'
            checked={report.transactionsValue}
            onCheckedChange={(value) => {
              setReport({ ...report, transactionsValue: value })
            }}
          />
          <Switch
            label='Сумма транзакций'
            checked={report.transactionsSummary}
            onCheckedChange={(value) => {
              setReport({ ...report, transactionsSummary: value })
            }}
          />
        </div>
        {report.type === EReportType.Partners
          ?
          <div className="flex flex-col gap-2">
            <Switch
              label='Количество переходов'
              checked={report.transitValue}
              onCheckedChange={(value) => {
                setReport({ ...report, transitValue: value })
              }}
            />
            <Switch
              label='Суммарная стоимость корзин с которыми пользователь переходит к партнерам'
              checked={report.totalPartnerCartCost}
              onCheckedChange={(value) => {
                setReport({ ...report, totalPartnerCartCost: value })
              }}
            />
            <Switch
              label='Средняя стоимость корзины пользователя'
              checked={report.averageUserCartCost}
              onCheckedChange={(value) => {
                setReport({ ...report, averageUserCartCost: value })
              }}
            />
            <Switch
              label='Номенклатурная доля оборудования в корзине пользователя'
              checked={report.nomenclaturePartEquipInUserCart}
              onCheckedChange={(value) => {
                setReport({ ...report, nomenclaturePartEquipInUserCart: value })
              }}
            />
            <Switch
              label='Денежная доля  оборудования в корзине пользователя'
              checked={report.moneyPartEquipInUserCart}
              onCheckedChange={(value) => {
                setReport({ ...report, moneyPartEquipInUserCart: value })
              }}
            />
          </div>
          :
          <div className="flex flex-col gap-2">
            <Switch
              label='Количество добавлений'
              checked={report.addictionNumber}
              onCheckedChange={(value) => {
                setReport({ ...report, addictionNumber: value })
              }}
            />
            <Switch
              label='Среднее количество в корзине одного пользователя'
              checked={report.averageCountInUserCart}
              onCheckedChange={(value) => {
                setReport({ ...report, averageCountInUserCart: value })
              }}
            />
          </div>
        }
      </div>
      <div className="flex gap-4 mt-14">
        <Button onClick={onDownloadReport} noFish variant="primary" rounded="big" className="w-fit">Скачать отчёт</Button>
        <Button onClick={onDownloadLogs} noFish variant="secondary" rounded="big" className="w-fit">Скачать логи</Button>
      </div>
    </div>
  );
}

export default Analytics;

