import { FC, useEffect, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Select from "../UI/inputs/dropdown/Select";
import TextBox from "../UI/inputs/textInput/TextBox";
import Button from "../UI/buttons/Button";
import { CreatePartnerRequest } from "../../API/admin/partnersApi/partnersInterfaces";
import { PartnerCategory } from "../../assets/interfaces/IPartner";
import { onGeneratePassword, onTextCopy } from "../pages/basicPages/utils/utils";
import PencilIcon from "../../assets/icons/PencilIcon";
import Tooltip from "../UI/Tooltip/Tooltip";
import CopyIcon from "../../assets/icons/CopyIcon";

interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    onCreatePartner: (partnerData: CreatePartnerRequest) => void
}

const PartnerCategories = [
    { name: "По корзине и бирже", value: "basket" },
    { name: "По бирже", value: "exchange" }
]

const CreatePartnerModal: FC<IProps> = ({ isOpen, toggleOpen, onCreatePartner }) => {
    type Category = {
        value: string;
        name: string;
    }
    const [partnerCategories, setPartnerCategories] = useState<Category[]>([])
    const [selectedCaregory, setSelectedCategory] = useState<PartnerCategory>("exchange")
    const [partnerName, setPartnerName] = useState<string>()
    const [partnerLogin, setPartnerLogin] = useState<string>()
    const [partnerPassword, setPartnerPassword] = useState<string>('')
    const [partnerB2bLink, setPartnerB2bLink] = useState<string>()
    const [partnerB2cLink, setPartnerB2cLink] = useState<string>()

    useEffect(() => {
        setPartnerCategories(PartnerCategories)
    }, [])

    const onCreate = () => {
        if (selectedCaregory && partnerName && partnerLogin && partnerB2bLink && partnerPassword && (selectedCaregory === "exchange" || partnerB2cLink)) {
            const createPartnerData = { category: selectedCaregory, name: partnerName, login: partnerLogin, b2bLink: partnerB2bLink, password: partnerPassword } as CreatePartnerRequest
            if (selectedCaregory === "basket") createPartnerData.b2cLink = partnerB2cLink
            onCreatePartner(createPartnerData)
        }
    }

    return (<ModalBase className="rounded-2xl" onOpenChange={toggleOpen} open={isOpen}>
        <div className="w-[320px] flex flex-col items-center">
            <span className="w-full flex items-start justify-end -mt-6 -mr-16">
                <IconButton onClick={toggleOpen} size={"small"}><CrossIcon size={16} /></IconButton>
            </span>
            <div className="w-full flex flex-col items-center gap-6 -mt-4">
                <h1 className="w-full font-semibold text-2xl select-none">Создание партнера</h1>
                <div className="w-full">
                    <Select onValueChange={(value: PartnerCategory) => setSelectedCategory(value)} placeholder="Категория партнера" getItemTitle={(item) => item.name}
                        getItemValue={(item) => item.value} items={partnerCategories} />
                </div>
                <div className="w-full">
                    <TextBox onChangeText={(text: string) => setPartnerName(text)} label="Имя партнера" />
                </div>
                <div className="w-full">
                    <TextBox onChangeText={(text: string) => setPartnerLogin(text)} label="Логин партнера" />
                </div>
                <div className="w-full">
                    <TextBox onChangeText={(text: string) => setPartnerB2bLink(text)} label="Точка доступа b2b" />
                </div>
                {selectedCaregory === "basket" && <div className="w-full">
                    <TextBox onChangeText={(text: string) => setPartnerB2cLink(text)} label="Точка доступа b2c" />
                </div>}
                <div className="flex items-center justify-between gap-2 w-full">
                    <div className="w-full">
                        <TextBox type="password" value={partnerPassword} onChangeText={(text: string) => setPartnerPassword(text)} label="Пароль партнера" />
                    </div>
                    <div className="flex gap-2">
                        <Tooltip text="Сгенерировать новый пароль">
                            <IconButton
                                size="big"
                                className=" !text-lg"
                                variant="primary"
                                onClick={() => setPartnerPassword(onGeneratePassword())}
                            >
                                <PencilIcon size={18} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip text="Скопировать пароль">
                            <IconButton
                                size="big"
                                className=" !text-lg"
                                variant="primary"
                                onClick={() => onTextCopy(partnerPassword, "Пароль")}
                            >
                                <CopyIcon size={18} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="w-full">
                    <Button onClick={onCreate} disabled={!selectedCaregory || !partnerName || !partnerLogin ||
                        !partnerB2bLink || !partnerPassword || !(selectedCaregory === "exchange" || partnerB2cLink)} noFish>Создать партнера</Button>
                </div>
            </div>
        </div>
    </ModalBase>);
}

export default CreatePartnerModal;