import { useEffect, useState } from "react";
import Paper from "../layout/Paper";
import ReactInputVerificationCode from "react-input-verification-code";
import TextBox from "../UI/inputs/textInput/TextBox";
import Select from "../UI/inputs/dropdown/Select";
import Button from "../UI/buttons/Button";
import Tooltip from "../UI/Tooltip/Tooltip";
import PasswordBox from "../UI/inputs/textInput/PasswordBox";
import Checkbox from "../UI/inputs/checkBox/CheckBox";
import EmailErrorAuthModal from "../modals/EmailErrorAuthModal";
import { GetPayloadResponse, PaylodParam } from "../../API/base/payloadApi/payloadInterfaces";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { ResponseContainer } from "../../API/BaseApi";
import { GetPayload } from "../../API/base/payloadApi/payloadFunctions";
import { Login, Registrarion, VerifiConfirm, Verification } from "../../API/base/authRegApi/authRegFunctions";
import { checkEmail, checkInn } from "../../utils/validators";
interface IProps {
  onAuth(): void;
}

const RegForm = (props: IProps) => {
  type Step = "form" | "emailAccept" | "inputPass"

  const [currentStep, setCurrentStep] = useState<Step>("form")
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [emailSubscribe, setEmailSubscribe] = useState<boolean>(false)
  const [consentObtained, setConsentObtained] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [repeatPasswordError, setRepeatPasswordError] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [specializationId, setSpecializationId] = useState<string>("")
  const [city, setCity] = useState<string>("")
  const [companyName, setСompanyName] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [codeError, setCodeError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [stepTwoAccess, setStepTwoAccess] = useState<boolean>(false)
  const [timerIsStart, setTimerIsStart] = useState<boolean>(false)
  const [timerValue, setTimerValue] = useState<number>(0)
  const [failCount, setFailCount] = useState<number>(0)
  const [getCodeCount, setGetCodeCount] = useState<number>(0)
  const [supportModal, setSuppurtModal] = useState<boolean>(false)
  const [registrationAccess, setRegistrationAccess] = useState<boolean>(false)
  const [specializations, setSpecializations] = useState<Array<PaylodParam>>([])
  const [inn, setInn] = useState<string>()
  const [innError, setInnError] = useState<boolean>(false)

  useEffect(() => {
    onGetPayload()
  }, [])

  useEffect(() => {
    const isValid = (r: string) => {
      return r.length >= 8 && r.length <= 16 && /^[a-zA-z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~.]+$/.test(r)
    }
    if (password === repeatPassword) {
      setRepeatPasswordError(false)
    }
    else {
      setRepeatPasswordError(true)
    }
    if (isValid(password) || password.length === 0) {
      setPasswordError(false)
    }
    else {
      setPasswordError(true)
    }
  }, [password, repeatPassword])

  useEffect(() => {
    if (password && !passwordError &&
      repeatPassword && !repeatPasswordError &&
      consentObtained) {
      setRegistrationAccess(true)
    }
    else {
      setRegistrationAccess(false)
    }
  }, [password, repeatPassword, consentObtained, passwordError, repeatPasswordError])

  useEffect(() => {
    if (email && !emailError &&
      inn && !innError &&
      firstName && lastName && city &&
      companyName && specializationId) {
      setStepTwoAccess(true)
    }
    else {
      setStepTwoAccess(false)
    }
  }, [email, firstName, lastName, city, companyName, specializationId, emailError, inn, innError])

  useEffect(() => {
    if (failCount === 3) {
      setSuppurtModal(true)
    }
  }, [failCount])

  let intervalId: any

  useEffect(() => {
    if (timerIsStart) {
      let seconds = 60;
      setTimerValue(seconds)
      const tickFunk = () => {
        if (seconds > 0) {
          seconds--
          setTimerValue(seconds)
        }
        else {
          clearInterval(intervalId)
          setTimerIsStart(false)
        }
      }
      intervalId = setInterval(tickFunk, 1000)
    }
  }, [timerIsStart])

  const onEmailVerification = () => {
    Verification(email)
    setCurrentStep("emailAccept")
    setTimerIsStart(true)
    setGetCodeCount(getCodeCount + 1)
  }

  const onSetEmail = (email: string) => {
    if (
      checkEmail(email) ||
      email.length === 0
    ) {
      setEmailError(false);
      setEmail(email);
    } else {
      setEmailError(true);
    }
  }

  const onConfirmEmail = () => {
    VerifiConfirm({ codeFromEmail: code, email: email }).then((r: ResponseContainer<null>) => {
      if (r.status !== "error") {
        setCurrentStep("inputPass")
      }
      else {
        setCodeError(true)
        setFailCount(failCount + 1)
      }
    })
  }
  const onGetPayload = () => {
    GetPayload({ specializations: true }).then((r: ResponseContainer<GetPayloadResponse>) => {
      setSpecializations(r.data?.specializations || [])
    })
  }

  const onSetInn = (innText: string) => {
    if (checkInn(innText)) {
      setInnError(false)
    }
    else {
      setInnError(true)
    }
    setInn(innText)
  }

  const onTryRegistration = () => {
    Registrarion({ email, firstName, lastName, city, companyName, consentObtained, password, specializationId, inn: `${inn}`, emailSubscribe }).then((r: ResponseContainer<null>) => {
      if (r.status !== "error") {
        Login({ username: email, password: password }).then((r: ResponseContainer<null>) => {
          if (r.status !== "error") {
            props.onAuth();
          }
        });
      }
      else {
      }
    })
  }

  const passwordRules = <div className="flex flex-col gap-2">
    <p>Требования и допустимые символы:</p>
    <div className="flex text-gray-2 pl-3"><li /><p className="ml-[-6px]">Длина от 8 до 16 символов;</p></div>
    <div className="flex text-gray-2 pl-3"><li /><p className="ml-[-6px]">Строчные и заглавные буквы латинского алфавита;</p></div>
    <div className="flex text-gray-2 pl-3"><li /><p className="ml-[-6px]">Цифры от 0 до 9;</p></div>
    <div className="flex text-gray-2 pl-3"><li /><p className="ml-[-6px]">{"Символы: ! \" # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \ ] ^ _` { | } ~."}</p></div>
  </div>


  return <Paper className="flex w-full flex-col gap-8 px-10 pt-8">
    {currentStep === "form" && <div className="flex w-full flex-col gap-6">
      <TextBox
        defaultValue={email}
        error={emailError}
        description="Некорректный e-mail"
        required
        label="E-mail"
        onChange={(ev) => {
          onSetEmail(ev.target.value);
        }}
      />
      <div className="gap-6 flex">
        <div className="flex-1"><TextBox
          defaultValue={firstName}
          required
          label="Имя"
          onChange={(ev) => {
            setFirstName(ev.target.value);
          }}
        /></div>
        <div className="flex-1">
          <TextBox
            defaultValue={lastName}
            required
            label="Фамилия"
            onChange={(ev) => {
              setLastName(ev.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex items-end gap-6">
        <TextBox styleClasses={{ root: "w-full" }}
          defaultValue={city}
          required
          label="Город"
          onChange={(ev) => {
            setCity(ev.target.value);
          }}
        />
      </div>
      <TextBox
        defaultValue={companyName}
        required
        label="Наименование организации"
        onChange={(ev) => {
          setСompanyName(ev.target.value);
        }}
      />
      <Select
        styleClasses={{ scroll: "max-h-[240px]" }}
        searchable
        value={specializationId}
        placeholder="Классификатор партнера"
        onValueChange={(specId: string) => { setSpecializationId(specId) }}
        items={specializations}
        getItemTitle={function (item: { name: string, id: string }): string {
          return item.name;
        }} getItemValue={function (item: { name: string, id: string }): string {
          return item.id;
        }} />
      <TextBox
        defaultValue={inn}
        error={innError}
        description="Некорректный ИНН"
        required
        label="ИНН"
        onChange={(ev) => {
          onSetInn(ev.target.value);
        }}
      />
      <Button
        noFish
        disabled={!stepTwoAccess}
        onClick={() => {
          onEmailVerification()
        }}
      >
        Продолжить
      </Button>
    </div>}
    {currentStep === "emailAccept" && <div className="flex w-full flex-col gap-4">
      <div onClick={() => setCurrentStep("form")} className="flex cursor-pointer text-accent items-center select-none"><ArrowRightIcon size={16} className="rotate-180" /><p>Назад к регистрации</p></div>
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-semibold">Подтвердите ваш e-mail</h1>
        <p>На почтовый ящик <span className="font-semibold whitespace-pre-wrap">{email}</span> было отправлено письмо с кодом подтверждения. Для доступа к личному кабинету вам необходимо подтвердить свой e-mail.</p>
        <p>В случае необнаружения письма проверьте папку «Спам».</p>
      </div>
      <ReactInputVerificationCode onChange={(code: string) => {
        setCodeError(false)
        setCode(code)
      }}
        placeholder={""} autoFocus length={6} />
      <p className="text-xs text-system-red h-4">{codeError && "Неверный код подтверждения"}</p>
      <Button
        noFish
        disabled={code.length <= 5 || codeError}
        onClick={() => {
          onConfirmEmail()
        }}
      >
        Продолжить
      </Button>
      {getCodeCount <= 2 && <div>
        {!timerValue ? <Button noFish variant="secondary"
          onClick={() => {
            onEmailVerification()
          }}
        >
          Отправить код повторно
        </Button> : <p className="text-center text-gray-4">
          {`Отправить код повторно можно через 0:${timerValue >= 10 ? timerValue : `0${timerValue}`}`}
        </p>}
      </div>}
      <p onClick={() => { setCurrentStep("form") }} className="cursor-pointer w-1/3 m-auto text-center text-accent font-semibold select-none">Изменить e-mail</p>
    </div>}
    {currentStep === "inputPass" && <div className="flex w-full flex-col gap-6">
      <h1 className="text-2xl font-semibold">Создайте пароль</h1>
      <div className="w-full">
        <Tooltip
          side="right"
          dropChild={passwordRules}
          open={passwordError}
        >
          <PasswordBox
            description="Пароль не соответствует требованиям"
            error={passwordError}
            label="Новый пароль"
            required
            onChange={(ev) => {
              setPassword(ev.target.value);
            }}
          />
        </Tooltip>
      </div>
      <PasswordBox
        description="Указанные пароли не совпадают"
        error={repeatPasswordError}
        label="Повторите пароль"
        required
        onChange={(ev) => {
          setRepeatPassword(ev.target.value);
        }}
      />
      <Checkbox styleClasses={{ root: "min-h-[20px] min-w-[20px] !mt-1", container: "!items-start" }} checked={emailSubscribe}
        onCheckedChange={() => setEmailSubscribe(!emailSubscribe)}
        label="Я хочу получать новости и коммерческую информацию от Schneider Electric
				 и ee дочерних компаний c помощью электронных средств связи,
				  таких как электронная почта, и я даю согласие на сбор информации об
				   открытии и кликах по этим электронным письмам (с использованием невидимых пикселей на изображениях),
				    чтобы измерять эффективность наших коммуникаций и улучшать их. Для получения более подробной информации,
					 пожалуйста, прочитайте нашу Политику конфиденциальности." />
      <Checkbox styleClasses={{ root: "min-h-[20px] min-w-[20px] !mt-1", container: "!items-start" }} checked={consentObtained}
        onCheckedChange={() => setConsentObtained(!consentObtained)}
        label="Заполняя выбор регистрационной формулы, я даю согласие АО «Шнейдер Электрик»
			 и компаниям группы Schneider Electric на обработку данных, содержащихся в природных
			  ресурсах в соответствии с условиями и надлежащей обработкой данных о природных ресурсах,
			   формирование Политики конфиденциальности соответствующих компаний, включение регистрации
			    в систему учета клиентских, доступ к приложениям и ресурсам Schneider Electric и доступ
				 к ним, в том числе к трансграничной передаче в соответствии с условиями пользователей,
				  относящимися к приложениям и ресурсам." />
      <div className="flex flex-col my-3 text-sm">
        <p>Мы обрабатываем информацию о регистрации учетной записи и журналы подключений для аутентификации и управления доступом к приложениям...</p>
        <p className="text-accent">Уведомление о конфиденциальности</p>
      </div>
      <Button
        noFish
        disabled={!registrationAccess}
        onClick={() => {
          onTryRegistration()
        }}
      >
        Зарегистрироваться
      </Button>
    </div>}
    <EmailErrorAuthModal
      onOpenChange={setSuppurtModal}
      open={supportModal}
    />
  </Paper>
}

export default RegForm