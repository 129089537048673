import { FC, useEffect, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import SearchBox from "../UI/inputs/textInput/SearchBox";
import ScrollArea from "../UI/wrappers/ScrollArea";
import { IPartner } from "../../assets/interfaces/IPartner";
import Checkbox from "../UI/inputs/checkBox/CheckBox";
import Button from "../UI/buttons/Button";
import { GetPayload } from "../../API/base/payloadApi/payloadFunctions";
import { ResponseContainer } from "../../API/BaseApi";
import { GetPayloadResponse } from "../../API/base/payloadApi/payloadInterfaces";
import Loader from "../UI/loader/loader";
import systemeIcon from "../../assets/systemeIcon.svg"

interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    addedPartnersIds: string[];
    addPartners: (partners: IPartner[]) => void;
}

const AddPartnersModal: FC<IProps> = ({ isOpen, toggleOpen, addedPartnersIds, addPartners }) => {
    const [searchText, setSearchText] = useState<string>();
    const [partners, setPartners] = useState<IPartner[]>([]);
    const [selectedPartnersIds, setSelectedPartnersIds] = useState<string[]>([])

    const [partnersLoading, setPartnersLoading] = useState<boolean>(false)

    useEffect(() => {
        onGetPartners()
    }, [])

    const onSelectPartner = (partnerId: string) => {
        setSelectedPartnersIds([...selectedPartnersIds, partnerId])
    }

    const onDeletePartnder = (partnerId: string) => {
        setSelectedPartnersIds(selectedPartnersIds.filter((id) => id !== partnerId))
    }

    const onGetPartners = () => {
        setPartnersLoading(true)
        GetPayload({ partners: true }).then((r: ResponseContainer<GetPayloadResponse>) => { //TODO: Раскомментить при рабочем API
            if (r.status !== "error" && r.data?.partners) {
                const withoutAddedPartners = r.data.partners.filter((p) => addedPartnersIds?.findIndex((addedId) => addedId === p.id) === -1)
                setPartners(withoutAddedPartners)
            }
            setPartnersLoading(false)
        })
    }

    const onAddPartners = () => {
        const addingPartners = selectedPartnersIds.map((pId) => {
            const pData = partners.find((p) => p.id === pId)
            return { ...pData } as IPartner
        })
        addPartners(addingPartners)
    }

    const showPartnersList = () => {
        if (!searchText) {
            return partners.map((p: IPartner) => {
                const isSelected = selectedPartnersIds.findIndex((id) => id === p.id) >= 0;
                return <div key={p.id} className="w-full flex">
                    <div className="flex items-center gap-3">
                        <div>
                            <Checkbox onCheckedChange={(checked: boolean) => checked ? onSelectPartner(p.id) : onDeletePartnder(p.id)} checked={isSelected} />
                        </div>
                        <img className="!w-8 !h-8 select-none" src={p.avatar || systemeIcon} alt="IMG" />
                        <p>{p.name}</p>
                    </div>
                </div>
            })
        }
        else {
            return partners.filter((r: IPartner) => r.name.toLowerCase().includes(searchText.toLowerCase())).map((p: IPartner) => {
                const isSelected = selectedPartnersIds.findIndex((id) => id === p.id) >= 0;
                return <div key={p.id} className="w-full flex">
                    <div className="flex items-center gap-3">
                        <div>
                            <Checkbox onCheckedChange={(checked: boolean) => checked ? onSelectPartner(p.id) : onDeletePartnder(p.id)} checked={isSelected} />
                        </div>
                        <img className="!w-8 !h-8 select-none" src={p.avatar} alt="IMG" />
                        <p>{p.name}</p>
                    </div>
                </div>
            })
        }
    }

    return (<ModalBase className="rounded-2xl !px-0 !py-0" onOpenChange={toggleOpen} open={isOpen}>
        <div className="w-[460px] min-h-[480px] h-[500px]  flex flex-col items-center pt-8">
            <span className="w-full flex items-start justify-end -mt-6 mr-4">
                <IconButton onClick={toggleOpen} size={"small"}><CrossIcon size={16} /></IconButton>
            </span>
            <div className="flex-1 w-full flex flex-col items-center gap-4 -mt-2 overflow-hidden">
                <h1 className="w-full font-semibold text-lg px-10 select-none">Добавить партнера</h1>
                <div className="flex gap-3 items-center w-full px-10">
                    <SearchBox parentControl value={searchText} onChangeText={setSearchText} className="border-t-0 border-r-0 border-l-0 rounded-none !h-10 flex-1"
                        placeholder="Поиск" />
                </div>
                <div className="flex flex-col w-full flex-1 overflow-hidden">
                    <ScrollArea className="px-10 pb-2">
                        {showPartnersList().length > 0 ? <div className="w-full flex flex-col justify-start gap-6">
                            {showPartnersList()}
                        </div> :
                            <div className="flex h-[290px] justify-center">
                                {!partnersLoading ? <span className="w-64 text-center m-auto text-gray-3 font-normal text-base">
                                    {!searchText ? "Здесь будут отображены результаты поиска" : "По вашему запросу нет результатов"}
                                </span> : <Loader />}
                            </div>}
                    </ScrollArea>
                    <div className="bg-white shadow-3 py-4 px-8 flex items-center gap-3">
                        <Button onClick={onAddPartners} disabled={selectedPartnersIds.length === 0} className="rounded-lg w-28" size="small" noFish>Добавить</Button>
                        <Button onClick={toggleOpen} variant="secondary" className="rounded-lg w-28" size="small" noFish>Отмена</Button>
                    </div>
                </div>
            </div>
        </div>
    </ModalBase>);
}

export default AddPartnersModal;