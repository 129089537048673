import { FC, useEffect, useState } from "react";
import { Basket, IScenario } from "../../../../../../assets/interfaces/IScenario";
import Button from "../../../../../UI/buttons/Button";
import ReferenceTable from "../../../../../UI/table/ReferenceTable";
import clsx from "clsx";
import { IReference } from "../../../../../../assets/interfaces/IReference";

interface IProps {
    data: IScenario;
    goToScenary?: (scenario: IScenario) => void;
    isEdit?: boolean;
    setScenarioData?: (data: IScenario) => void
    basketReferences?: IReference[]
    onSetScenarioPartners?: (basketList?: Basket[]) => void
}

const Scenario: FC<IProps> = ({ data, goToScenary, isEdit, setScenarioData, basketReferences, onSetScenarioPartners }) => {
    ///TEST
    type RefsStockInfo = {
        inStock: number,
        notInStock: number,
        partly: number,
    }
    const [refsStockInfo, setRefsStockInfo] = useState<RefsStockInfo>({ inStock: 0, notInStock: 0, partly: 0 })

    useEffect(() => {
        if (!isEdit) {
            onSetNewStockInfo()
        }
    }, [])

    const onSetNewStockInfo = () => {
        const newStockInfo = { ...refsStockInfo }
        let inStock = 0;
        let notInStock = 0;
        let partly = 0;
        const scenarioRefs = [] as IReference[];
        for (const basket of data.baskets) {
            for (const ref of basket.references) {
                const scenarioRefsArrIndex = scenarioRefs.findIndex((r) => r.referenceSku === ref.referenceSku)
                if (scenarioRefsArrIndex >= 0) {
                    //@ts-ignore
                    scenarioRefs[scenarioRefsArrIndex] = { ...scenarioRefs[scenarioRefsArrIndex], count: scenarioRefs[scenarioRefsArrIndex].count as number + ref.count }
                }
                else {
                    scenarioRefs.push(ref)
                }
            }
        }
        for (const ref of basketReferences as IReference[]) {
            const refScenarioIndex = scenarioRefs.findIndex((r) => r.referenceSku === ref.referenceSku)
            if (refScenarioIndex >= 0) {
                if (ref.count && scenarioRefs[refScenarioIndex].count as number >= 0) {
                    //@ts-ignore
                    if (ref.count <= scenarioRefs[refScenarioIndex].count) {
                        inStock += 1
                    }
                    else {
                        partly += 1
                    }
                }
            }
            else {
                notInStock += 1
            }
        }
        newStockInfo.inStock = inStock
        newStockInfo.partly = partly
        newStockInfo.notInStock = notInStock
        setRefsStockInfo(newStockInfo)
    }

    const onDeleteBasket = (companyName: string) => {
        if (setScenarioData) {
            const newData: IScenario = { ...data, baskets: data.baskets.filter((b) => b.partnerName !== companyName) }
            setScenarioData(newData)
            if (onSetScenarioPartners) {
                onSetScenarioPartners(newData.baskets)
            }
        }
    }
    const onChangeBasketReferences = (companyName: string, references: IReference[]) => {
        if (setScenarioData) {
            const newData: IScenario = { ...data }
            const newBaskets = [...newData.baskets]
            const newBasketIndex = newBaskets.findIndex((b) => b.partnerName === companyName)
            const newBasket: Basket = { ...newBaskets[newBasketIndex], references: references }
            if (newBasket.references.length === 0) {
                onDeleteBasket(companyName)
            }
            else {
                newBaskets[newBasketIndex] = newBasket
                newData.baskets = newBaskets
                setScenarioData(newData)
            }
        }
    }
    const tablesList = data.baskets.map((b, i) => {
        return <ReferenceTable onChangeBasketReferences={onChangeBasketReferences} onDeleteBasket={onDeleteBasket} edit={isEdit} references={b.references} companyName={b.partnerName} companyAvatar={b.partnerImg} key={i} />
    })
    return (<div className={clsx("w-full flex flex-col bg-white rounded-lg gap-4", !isEdit && "shadow-5 px-6 py-5 ")}>
        {!isEdit && <div className="flex gap-5 items-center">
            <p className="text-sm text-accent">В наличии: {refsStockInfo.inStock}</p>
            <p className="text-sm text-black">Частично: {refsStockInfo.partly}</p>
            <p className="text-sm text-gray-3">Нет на складе: {refsStockInfo.notInStock}</p>
            <span className="flex flex-1 justify-end">
                <Button onClick={() => goToScenary && goToScenary(data)} className="w-[170px] rounded-lg" size="small" noFish>Перейти к сценарию</Button>
            </span>
        </div>}
        <div className="flex flex-col gap-4 w-full">
            {tablesList}
        </div>
    </div>);
}

export default Scenario;