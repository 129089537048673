import {P, requestDelete, requestGet, requestPost, requestPostForm, requestPut} from "../../requester";
import {
	ChangePasswordRequest,
	ChangeProfileRequest,
	getProfileResponse,
} from "./profileInterfaces";

export function GetProfileInfo() {
	return requestGet<getProfileResponse>(P.Profile);
}

export function ChangeProfileInfo(args: ChangeProfileRequest) {
	return requestPut<null>(P.Profile, args);
}

export function ChangePassword(args: ChangePasswordRequest) {
	return requestPut<null>(P.Password, args);
}
